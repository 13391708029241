import axios from 'axios';
import { handleErrorFromRequest } from '@services/handleErrorService.js';
axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

export async function getSharedAssetHtml({ shareableId, shareableType }) {
  try {
    const response = await axios.get(`/shared_assets/${shareableId}/shared_asset_html`, {
      headers: { Accept: 'application/json' },
      params: { shareable_type: shareableType },
    });
    return response;
  } catch (error) {
    handleErrorFromRequest(error);
  }
}

export async function shareByEmail({ shareableId, recipientEmails, shareableType, base64Image }) {
  try {
    const response = await axios.put(
      `/shared_assets/${shareableId}/share_by_email`,
      { recipient_emails: recipientEmails, shareable_type: shareableType, base64_image: base64Image },
      { headers: { Accept: 'application/json' } },
    );
    return response;
  } catch (error) {
    handleErrorFromRequest(error);
  }
}
